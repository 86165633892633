/* latin-ext */

@font-face {
	font-family: 'Lato';
	font-style: normal;
	font-weight: 300;
	font-display: swap;
	src: local('Lato Light'), local('Lato-Light'), url(https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh7USSwaPGR_p.woff2) format('woff2');
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}


/* latin */

@font-face {
	font-family: 'Lato';
	font-style: normal;
	font-weight: 300;
	font-display: swap;
	src: local('Lato Light'), local('Lato-Light'), url(https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh7USSwiPGQ.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}


/* latin-ext */

@font-face {
	font-family: 'Lato';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: local('Lato Regular'), local('Lato-Regular'), url(https://fonts.gstatic.com/s/lato/v16/S6uyw4BMUTPHjxAwXjeu.woff2) format('woff2');
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}


/* latin */

@font-face {
	font-family: 'Lato';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: local('Lato Regular'), local('Lato-Regular'), url(https://fonts.gstatic.com/s/lato/v16/S6uyw4BMUTPHjx4wXg.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}


/* cyrillic-ext */

@font-face {
	font-family: 'Alegreya Sans SC';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: local('Alegreya Sans SC Regular'), local('AlegreyaSansSC-Regular'), url(https://fonts.gstatic.com/s/alegreyasanssc/v9/mtGh4-RGJqfMvt7P8FUr0Q1j-Hf1BkRl9xxA.woff2) format('woff2');
	unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}


/* cyrillic */

@font-face {
	font-family: 'Alegreya Sans SC';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: local('Alegreya Sans SC Regular'), local('AlegreyaSansSC-Regular'), url(https://fonts.gstatic.com/s/alegreyasanssc/v9/mtGh4-RGJqfMvt7P8FUr0Q1j-Hf1Bk1l9xxA.woff2) format('woff2');
	unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}


/* greek-ext */

@font-face {
	font-family: 'Alegreya Sans SC';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: local('Alegreya Sans SC Regular'), local('AlegreyaSansSC-Regular'), url(https://fonts.gstatic.com/s/alegreyasanssc/v9/mtGh4-RGJqfMvt7P8FUr0Q1j-Hf1BkVl9xxA.woff2) format('woff2');
	unicode-range: U+1F00-1FFF;
}


/* greek */

@font-face {
	font-family: 'Alegreya Sans SC';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: local('Alegreya Sans SC Regular'), local('AlegreyaSansSC-Regular'), url(https://fonts.gstatic.com/s/alegreyasanssc/v9/mtGh4-RGJqfMvt7P8FUr0Q1j-Hf1Bkpl9xxA.woff2) format('woff2');
	unicode-range: U+0370-03FF;
}


/* vietnamese */

@font-face {
	font-family: 'Alegreya Sans SC';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: local('Alegreya Sans SC Regular'), local('AlegreyaSansSC-Regular'), url(https://fonts.gstatic.com/s/alegreyasanssc/v9/mtGh4-RGJqfMvt7P8FUr0Q1j-Hf1BkZl9xxA.woff2) format('woff2');
	unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}


/* latin-ext */

@font-face {
	font-family: 'Alegreya Sans SC';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: local('Alegreya Sans SC Regular'), local('AlegreyaSansSC-Regular'), url(https://fonts.gstatic.com/s/alegreyasanssc/v9/mtGh4-RGJqfMvt7P8FUr0Q1j-Hf1Bkdl9xxA.woff2) format('woff2');
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}


/* latin */

@font-face {
	font-family: 'Alegreya Sans SC';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: local('Alegreya Sans SC Regular'), local('AlegreyaSansSC-Regular'), url(https://fonts.gstatic.com/s/alegreyasanssc/v9/mtGh4-RGJqfMvt7P8FUr0Q1j-Hf1Bkll9w.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}


/* latin */

@font-face {
	font-family: 'Permanent Marker';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: local('Permanent Marker Regular'), local('PermanentMarker-Regular'), url(https://fonts.gstatic.com/s/permanentmarker/v9/Fh4uPib9Iyv2ucM6pGQMWimMp004La2Cfw.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

a {
	color: #838383;
}

a:hover {
	color: #5ce1e6;
	text-decoration: none!important;
}
body, html{
  font-family: 'lato'!important;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  letter-spacing: 0.5px;
}
h1,h2,h3,h4,h5,h6{
	font-family: 'Alegreya Sans SC';
}
#root{
  height: 100%;
}
p, ul, ol{
	color: #838383;
}
.contact-form .form-control {
    background-color: transparent;
}
@media (max-width:767px){
    .form-layout{
        width: 100%;
    }
}
@media (min-width:768px) and (max-width:1023px){
    .form-layout {
        width: 80%;
        margin: 0 auto;
    }
}
.slide-item img{
    height: 250px;
    object-fit: cover;
    object-position: center center;
    width: 100%;
}
.slide-item{
    position: relative;
    width: 95%;
    margin: 0 auto;
}
.slide-item:hover .img-overlay{
    display: block;
}
.img-overlay{
    background-color: rgba(0,0,0,.3);
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    display: none;
    transition: 0.5s;
}
.overlay-title{
    color: #fff;
    font-family: 'Alegreya Sans SC';
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: 1em;
    font-size: 16px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 75%;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -moz-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.react-multiple-carousel__arrow--left {
    left: calc(1% + 1px);
}
.react-multiple-carousel__arrow--right {
    right: calc(1% + 1px);
}
.react-multiple-carousel__arrow{
    min-width: 40px;
    min-height: 40px;
    border-radius: 0;
}
@media (max-width:767px){
    .slide-item{
        width: 100%;
    }
}
@media (min-width:1024px) and (max-width:1400px){
    .slide-item img{
        height: 200px;
    }
}
@media (min-width:768px) and (max-width:1023px){
    .slide-item img{
        height: 150px;
    }
    .img-overlay{
        display: block;
    }
    .react-multiple-carousel__arrow--left {
        left: calc(2% + 1px);
    }
    .react-multiple-carousel__arrow--right {
        right: calc(2% + 1px);
    }
    .react-multiple-carousel__arrow{
        min-width: 34px;
        min-height: 34px;
    }
    .react-multiple-carousel__arrow::before{
        font-size: 16px;
    }
}


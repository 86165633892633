.hr_text {
    width: 100%;
    text-align: center;
    overflow: hidden;
    margin: 10px 0;
    font-family: 'Alegreya Sans SC';
}
.hr_text>span {
    z-index: 1;
    position: relative;
    padding: 0 10px;
    display: inline-block;
    font-size: 14px;
    color: #838383;
    letter-spacing: 3px;
}
.hr_text>span:after, .hr_text>span:before {
    content: "";
    height: 1px;
    background-color: #e2e2e2;
    position: absolute;
    top: 10px;
}
.hr_text>span:before {
    left: -50vw;
    right: 100%;
}
.hr_text>span:after {
    right: -50vw;
    left: 100%;
}
.map{
    text-align: center;
}
.map img {
    width: 80%;
}
.footer-head{
    margin-bottom: 2em;
}
.social-icon {
    padding: 0;
    list-style: none;
}
.social-icon li {
    display: inline-block;
    width: 50%;
    text-align: center;
    margin-bottom: 2em;
    transition: 0.3s;
}
.social-icon button:focus{
    outline: none;
}
.social-icon li:hover .fa-instagram{
    color: #d62976;
}
.social-icon li:hover .fa-facebook{
    color: #3b5998;
}
.social-icon li:hover .fa-whatsapp{
    color: #25D366;
}
.social-icon li:hover .fa-twitter{
    color: #00acee;
}
.social-icon li:hover .fa-pinterest-p{
    color: #c8232c;
}
.social-icon li span {
    display: block;
    font-family: 'Alegreya Sans SC';
    font-size: 12px;
    letter-spacing: 2px;
    color: #838383;
    text-transform: uppercase;
}
.social-icon i {
    color: #5ce1e6;
    font-size: 26px;
    margin-bottom: 10px;
}
.footer {
    padding-top: 2em;
    padding-bottom: 2em;
}
.copyright {
    font-size: 12px;
    font-family: 'Alegreya Sans SC';
    text-transform: uppercase;
    letter-spacing: 1px;
    padding: 5px;
    background-color: #f9f5f2;
}
.highlight{
    color: #f80798;
    font-weight: 600;
    transition: 0.2s;
}
.highlight:hover{
    color: #838383;  
}
.subs-form input {
    width: 100%!important;
    margin-bottom: 1em;
    border-radius: 0;
}
.subs-form button{
    width: 100%;
}
.copyright ul {
    margin: 0;
    list-style: none;
    padding: 0;
}
.copyright ul li {
    display: inline-block;
    margin: 0 5px;
}
.cookieConsent div{
    margin: 10px 15px!important;
}
@media (max-width:767px){
    .cookieConsent div{
        font-size: 12px;
    }
    .cookieConsent {
        text-align: center;
    }
    .cookieConsent button{
        margin: 10px auto!important;
    }
    .footer {
        padding: 10px;
    }
    .copyright{
        text-align: center;
    }
    .copyright .text-right{
        text-align: center!important;
    }
    .social-icon{
        margin-bottom: 0;
    }
    .social-icon li{
        margin-bottom: 1em;
        width: 25%;
    }
    .footer-head {
        margin-bottom: 1em;
    }
    .social-icon li span{
        font-size: 10px;
        letter-spacing: 1px;
    }
    .social-icon i {
        font-size: 20px;
        margin-bottom: 10px;
    }
}
@media (min-width:768px) and (max-width:1023px){
    .social-icon li{
        margin-bottom: 15px;
    }
    .footer {
        padding-top: 1em;
        padding-bottom: 1em;
    }
    .social-icon{
        margin-bottom: 0;
    }
}
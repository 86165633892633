.main-nav{
	background-color: #f9f5f2;
    box-shadow: 0em 0.15em 10px 0px rgba(0,0,0,0.1);
}
.main-nav .navbar-nav{
	font-family: 'Alegreya Sans SC';
}
.main-nav .navbar-nav .nav-link{
    color: #838383;
    padding-right: 20px!important;
    padding-left: 20px!important;
    letter-spacing: 1px;
    transition: 0.3s;
}
.main-nav .navbar-nav .nav-link:hover {
    background-color: #f4eee9;
    border-radius: 5px;
}
.navlogo{
	width: 50px;
}
.main-nav {
    padding-top: 0!important;
    padding-bottom: 0!important     ;
}
.select-loc{
    color: #838383!important;
    padding-right: 20px!important;
    padding-left: 20px!important;
    letter-spacing: 1px;
    transition: 0.3s;
    border: none!important;
    background-color: transparent!important;
    padding: 0.5rem 1rem!important;
    text-transform: uppercase!important;
}
.select-loc:hover{
    background-color: #f4eee9!important;
    border-radius: 5px;
}
.select-loc:focus{
    outline: none!important;
    box-shadow: none!important;
}
.select-loc option{
    color: #838383!important;
}
.btn-loc{
    color: #838383;
    padding-right: 20px!important;
    padding-left: 20px!important;
    letter-spacing: 1px;
    transition: 0.3s;
    display: block;
    padding: 0.5rem 1rem;
    border: none;
    background-color: transparent;
}
.btn-loc:hover{
    background-color: #f4eee9!important;
    border-radius: 5px;
}
.btn-loc:focus{
    outline: none!important;
    box-shadow: none!important;
}
.loc-dd .dropdown-item{
    color: #838383;
    padding: 5px 10px;
}
.loc-dd .dropdown-item:focus{
    outline: none!important;
}
.loc-dd .dropdown-item.active, .loc-dd .dropdown-item:active{
    background-color: transparent;
    color: #fff!important;
}
.loc-dd .dropdown-item.is-active{
    background-color: #f80798!important;
    color: #fff!important;
    border-radius: 0;
}
.loc-dd .dropdown-menu{
    padding: 0;
}
.loc-dd .dropdown-toggle::after{
    margin-left: 0.5em!important;
    vertical-align: middle!important;
}
.navbar-toggler{
    padding: 0;
}
.navbar-toggler-icon{
    line-height: 1.5em;
    color: #fff;
    background-color: #f80798!important;
    border-radius: 4px;
}
.navbar-toggler:focus {
    outline: none;
}
.navbar-brand:focus{
    outline: none;
}
.navbar-toggler{
    padding: 0!important;
}
.is-active{
    background-color: #f4eee9;
    border-radius: 5px;
}
.search-div{
    width: 50%;
}
@media (min-width:768px){
    #mobile_search{
        display: none!important;
    }
}
@media (max-width:767px){
    .main-nav .navbar-nav .nav-link, .btn-loc{
        padding-left: 5px!important;
        padding-right: 5px!important;
    }
    .navbar-nav{
        padding: 10px 0;
    }
    .mbl-search-toggle{
        position: absolute;
        right: 60px;
        top: 14px;
    }
}
@media (min-width:768px) and (max-width:1023px){
    .navbar-nav {
        margin: 10px 0;
    }
    .mbl-search-toggle{
        display: none;
    }
    .menu-btn{
        position: absolute;
        right: 15px;
        top: 15px;
    }
    .search-div{
        width: 85%;
        margin-right: auto;
    }
    .search-form .search-activity{
        width: 75%!important;
    }
    .search-btn{
        width: 25%;
    }
}
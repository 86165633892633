.post-img {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 20px 0px;
    margin-bottom: 1em;
}
.post-img img {
    width: 100%;
    height: 400px;
    object-fit: cover;
    border-radius: 5px;
}
.post-title{
    text-align: center;
}
.post-title h2{
    font-family: 'Permanent Marker';
    color: #838383;
    letter-spacing: 1px;
    margin-bottom: 15px;
    margin-top: 15px;
}
.post-title h5{
    font-family: 'Alegreya Sans SC';
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 18px;
    margin-bottom: 10px;
}
.post-content strong{
    color: #f80798;
}
.post-content p {
    text-align: justify;
}
.post-tags{
    list-style: none;
    padding:0;
}
.post-tags li {
    color: #fff;
    display: inline-block;
    background-color: #f80798;
    padding: 2px 10px;
    border-radius: 4em;
    font-size: 12px;
    font-family: 'Alegreya Sans SC';
    letter-spacing: 2px;
    margin: 3px;
    transition: 0.3s;
}
.post-tags li:hover {
    background-color: #d1007e;
}
.new-post .media-left img {
    width: 80px;
    height: 80px;
    object-fit: cover;
    margin-right: 1em;
}
.new-post .media-heading {
    font-size: 16px;
    font-family: 'Alegreya Sans SC';
    text-transform: uppercase;
    line-height: 22px;
}
.new-post{
    margin-bottom: 15px;
}
.newpost-wrap{
    margin-bottom: 2em;;
}
.post-cat{
    color: #838383;
    font-family: 'Alegreya Sans SC';
    text-transform: uppercase;
    letter-spacing: 2px;
    list-style: square;
    font-size: 14px;
    margin-bottom: 1em;
}
.cat-list {
    padding-left: 20px;
    color: #838383;
    font-family: 'Alegreya Sans SC';
    text-transform: uppercase;
    letter-spacing: 2px;
    line-height: 30px;
    list-style: square;
    font-size: 14px;
}
.cat-list a{
    color: #838383;
}
.cat-list a:hover{
    color: #5ce1e6;
}
.auth-div h5 {
    font-size: 14px;
    color: #838383;
    font-family: 'Alegreya Sans SC';
    text-transform: uppercase;
    letter-spacing: 2px;
    line-height: 30px;
    margin: 0;
}
.auth-col3{
    text-align: right;
}
.share-post {
    padding: 0;
    list-style: none;
    text-align: center;
    margin: 0;
}
.share-post li {
    display: inline-block;
    background-color: #f80798;
    margin: 0 10px;
    color: #fff;
    width: 34px;
    height: 34px;
    line-height: 34px;
    border-radius: 50%;
    transition: 0.3s;
}
.share-post li:hover {
    background-color: #d1007e;
}
.auth-div {
    border-bottom: 1px solid #e2e2e2;
    border-top: 1px solid #e2e2e2;
    padding: 10px;
    margin: 2em 0;
    clear: both;
}
.post-controller-div img{
    width: 60px;
    height: 60px;
    object-fit: cover;
}
.post-controller-div .media-heading{
    font-size: 14px;
    font-family: 'Alegreya Sans SC';
    text-transform: uppercase;
    letter-spacing: 2px;
    line-height: 30px;
    margin: 0;
}
.post-controller-left .media-heading{
    margin-left: 10px;
}
.post-controller-right .media-heading{
    margin-right: 10px;
    text-align: right;
}
.comment-title{
    font-family: 'Alegreya Sans SC';
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: 1em;
    font-size: 18px;
}
.post-controller-div {
    margin-bottom: 2em;
}
.commenter{
    color: #838383;
    font-family: 'Alegreya Sans SC';
    text-transform: uppercase;
    letter-spacing: 2px;
    list-style: square;
    font-size: 14px;
    font-weight: 600;
}
.comment-text{
    font-size: 14px;
    font-weight: 300;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -moz-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 0;
}
.comment-box{
    padding: 1em;
    border: 1px solid #e2e2e2;
    margin-bottom: 1em;
}
.comment-box:nth-child(odd) .commenter-img {
    color: #ff55bb;
    text-shadow: 0 4px 5px #c9bdab, 0 2px 1px #730045;
}
.commenter-img {
    width: 100%;
    color: #5ce1e6;
    text-shadow: 0 4px 5px #c9bdab, 0 2px 1px #47abae;
    font-size: 4em;
    text-align: center;
    background-color: #f9f5f2;
    box-shadow: inset 0 0 16px rgba(172, 113, 113, 0.1), rgba(255, 255, 255, 0.1) 0 1px 0;
    width: 60px;
    height: 60px;
    font-size: 45px;
    line-height: 60px;
    margin-right: 15px;
}
.comment-date{
    color: #f80798;
    font-family: 'Alegreya Sans SC';
    text-transform: uppercase;
    letter-spacing: 2px;
    list-style: square;
    font-size: 14px;
    text-align: right;
    font-weight: 600;
}
.related-title{
    font-family: 'Permanent Marker';
    color: #f80798;
    letter-spacing: 1px;
    margin-bottom: 15px;
    margin-top: 15px;
    font-size: 24px;
    text-align: center;
}
.related-box {
    padding: 3em 0;
    background-color: #f9f5f2;
}
@media (max-width:767px){
    .comment-box{
        padding: 10px;
    }
    .commenter-img{
        width: 50px;
        height: 50px;
        font-size: 30px;
        line-height: 50px;
        margin-right: 10px;
    }
    .img-overlay{
        display: block!important;
    }
    .slide-item img{
        font-size: 20px;
    }
    .overlay-title{
        -webkit-line-clamp: 3;
        -moz-line-clamp: 3;
    }
    .post-img img{
        height: 150px;
    }
    .post-title h2{
        font-size: 22px;
    }
    .post-title h5{
        font-size: 18px;
    }
    .post-cat{
        font-size: 12px;
    }
    .new-post .media-left img {
        width: 50px;
        height: 50px;
    }
    .post-controller-div .media-heading{
        font-size: 12px;
    }
    .post-tags{
        text-align: center;
        margin-bottom: 0;
    }
    .related-box {
        padding: 1em 0;
    }
    .related-title{
        font-size: 20px;
    }
    .comment-date, .commenter{
        font-size: 12px;
    }
    .comment-text{
        font-size: 12px;
        line-height: 14px;
    }
}
@media (min-width:768px) and (max-width:1023px){
    .related-box {
        padding: 2em 0;
        background-color: #f9f5f2;
    }
    .share-post li{
        margin: 0 8px;
    }
}
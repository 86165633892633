.bg-home{
    /* background-image: url(../../assets/images/bg.jpg);
    background-size: cover; */
}
.gallery {
    list-style-type: none;
    padding: 0;
    margin: 0!important;
    width: 100%;
    position: relative;
}
.card-title{
    font-family: 'Alegreya Sans SC';
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 1px;
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -moz-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.card-text{
    text-align: center;
    color: #838383;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 12px;
}
.card-body{
    padding: 1em;
}
.quote-box {
    padding: 1em;
    text-align: center;
    border: 2px dashed rgba(0, 0, 0, 0.125);
    margin-bottom: 1em;
}
.quote-box span{
    display: block;
}
.quote-icon i {
    color: #554f4e;
    font-size: 20px;
    margin-bottom: 10px;
}
.quote-text {
    font-family: 'Permanent Marker';
    color: #f80798;
    font-size: 18px;
    letter-spacing: 1px;
}
.card-img-box {
    position: relative;
    overflow: hidden;
}
.card:hover .card-img-top {
    transform: scale(1.1);
}
.card-img-top{
    transition: 0.5s;
}
.card-overlay {
    background-color: rgba(0,0,0,0.4);
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: 0.5s;
}
.card-overlay-content i {
    background-color: #fff;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    text-align: center;
    line-height: 36px;
    /* margin-right: 10px; */
}
.card-overlay-content {
    padding: 10px;
    text-align: right;
}
.card:hover .card-overlay{
    opacity: 1;
}
.my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -30px; /* gutter size offset */
    width: auto;
}
.my-masonry-grid_column {
    padding-left: 30px; /* gutter size */
    background-clip: padding-box;
}
.noresult{
    text-align: center;
}
.noresult img{
    width: 70%;
}
.noresult-text {
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}
.noresult-text h4{
    counter-reset: #f80798;
}
.noresult-text h5{
    color: #838383;
}
/* Style your items */
.my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
    background: grey;
    margin-bottom: 30px;
}
@media (max-width:767px){
    .my-masonry-grid{
        margin-left: 0;
        width: 100%;
    }
    .my-masonry-grid_column{
        padding-left: 5px;
        padding: 0 5px;
    }
    .card-body {
        padding: 10px!important;
    }
    .card-title{
        font-size: 14px;
        margin-bottom: 6px!important;
    }
    .card-text{
        font-size: 10px;
    }
    .noresult-text{
        position: unset;
        transform: none;
    }
    .noresult-text {
        margin-bottom: -2em;
        margin-top: 1em;
    }
    .noresult-text h4{
        font-size: 20px;
    }
    .noresult-text h5{
        font-size: 18px;
    }
}
@media (min-width:768px) and (max-width:1023px){
    .noresult-text {
        top: 20%;
    }
    .noresult img {
        width: 100%;
    }
}
.heading {
    font-family: 'Permanent Marker';
    font-size: 2.5em;
    letter-spacing: 1px;
    margin-bottom: 20px;
    color: #f80199;
    letter-spacing: 3px;
}

.bg-lp {
    height: 100%;
    background-image: url(../../assets/images/bg.jpg);
    background-size: cover;
    background-position: top;
}

.content {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.content p {
    font-size: 24px;
    letter-spacing: 2px;
    color: rgb(131, 131, 131);
}

.bg-5 {
    position: absolute;
    top: 25%;
    left: 10%;
}

.bg-logo {
    background-image: url(../../assets/images/text-bg.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    padding: 20px 0;
    color: #fff;
}

@media (min-width: 1200px) and (max-width: 1440px) {
    .heading {
        font-size: 2em;
    }
    img {
        width: 65%;
    }
}

@media (min-width: 1024px) and (max-width: 1199px) {
    .heading {
        font-size: 2em;
    }
    img {
        width: 40%;
    }
    .content p {
        font-size: 18px;
    }
    .bg-6 {
        position: absolute;
        bottom: 0%;
        left: 0%;
        text-align: left;
        width: auto;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    img {
        width: 70%;
    }
    .content {
        width: 80%;
        top: 45%;
    }
    .bg-5 {
        position: absolute;
        top: 15%;
        left: 21%;
    }
}

@media (max-width: 767px) {
    .bg-3,
    .bg-5 {
        display: none;
    }
    .heading {
        font-size: 20px;
    }
    .content {
        width: 90%;
    }
    .content p {
        font-size: 18px;
    }
}
.blue-btn {
    background-color: #5ce1e6;
	color: #fff;
	letter-spacing: 1px;
}
.blue-btn:hover {
    background-color: #50d3d8;
    color: #fff;
}
::-webkit-input-placeholder { 
	color: rgb(210, 210, 210)!important;
	font-size: 14px;
}

:-ms-input-placeholder { 
	color: rgb(210, 210, 210)!important;
	font-size: 14px;
}

::placeholder {
color: rgb(210, 210, 210)!important;
font-size: 14px;
}
.page-gap{
	padding-top: 5em;
	padding-bottom: 2em;
	min-height: 70vh;
}
.sec-pad{
	padding-top: 2em;
	padding-bottom: 2em;
}
.sec-mar{
	margin-top: 2em;
	margin-bottom: 2em;
}
.sec-heading {
    background-image: url(../../assets/images/text-bg.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    padding: 16px 0;
    color: #fff;
    font-size: 24px;
    letter-spacing: 3px;
    font-family: 'Alegreya Sans SC';
    text-align: center;
    margin-bottom: 1em;
}
.page-layout {
    text-align: justify;
}
.page-layout h5 {
    color: #f80798;
    font-size: 1.25rem;
    font-weight: 600;
}
.cms-sub-heading {
    color: #838383!important;
}
.bg-layout{
    background-image: url(../../assets/images/bg-about.jpg);
}
.form-control{
	border-radius: 0!important;
}
.sub-heading{
	font-family: 'Alegreya Sans SC';
	letter-spacing: 1px;
	text-align: center;
	color: #838383;
	margin-bottom: 1em;
}
.form-layout {
    width: 60%;
    margin: 0 auto;
}
.alert{
    padding: 5px 10px;
    font-size: 14px;
    border-radius: 0;
    letter-spacing: 1px;
}
.alert-success {
    color: #ffffff;
    background-color: #76d374;
    border-color: #5db571;
}
@media (max-width:767px){
    .page-layout h5{
        font-size: 16px;
    }
    p{
        font-size: 14px;
    }
    .page-layout ul, .page-layout ol{
        padding-left: 20px;
        font-size: 14px;
    }
    .bg-layout {
        background-size: cover;
        background-position: center;
    }
    .sec-heading{
        font-size: 20px;;
    }
    .page-gap{
        padding-bottom: 1em;
    }
}
@media (min-width:768px) and (max-width:1023px){
    .page-gap {
        min-height: 75vh;
    }
}
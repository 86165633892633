.search-form .form-control{
	border-radius: 0;
	border: none;
}
.search-form .search-activity {
    width: 60%!important;
    border-top-left-radius: 1.25rem!important;
    border-bottom-left-radius: 1.25rem!important;
    border-right: 1px solid #f0f0f0;
}
.search-form .search-city{
	width: 25%!important;
}
.search-btn{
	width: 15%;
	border-radius: 0!important;
	border-top-right-radius: 1.25rem!important;
	border-bottom-right-radius: 1.25rem!important;
}
.form-control:focus{
    outline: none!important;
    box-shadow: none!important;
}
.card{
    margin-bottom: 1em;
}
@media (max-width:767px){
    .search-activity::placeholder{
        font-size: 12px;
    }
    .search-btn {
        font-size: 12px;
        height: 38px;
    }
    .search-btn i{
        display: none;
    }
    .search-form .search-activity{
        width: 70%!important;
    }
    .search-btn {
        width: 30%;
    }
    .search-form {
        margin: 10px 0;
    }
}
@media (min-width:768px) and (max-width:1023px){
    .search-btn {
        width: 35%;
    }
}
@media (min-width:1200px) and (max-width:1440px){
    .search-btn{
        width: 20%;
    }
}